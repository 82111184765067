/* src/components/Chatbot.css */
.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chatbot-toggle {
    background:none;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
}

.chatbot-toggle img {
    width: 77px; /* ou la taille souhaitée */
    height: 100px; /* ou la taille souhaitée */
}

.chat-window {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    max-width: 300px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: -360px;
    width: 500px;
    height: 300px;
    overflow: auto;
}
.chat-window:after {
    content:'';
    position:absolute;
    bottom:0;
    right:0;
}

.chat-choice {
    background-color: #F9D100; /* Couleur de fond jaune */
    color: #333; /* Couleur de texte foncée */
    border: none; /* Retirer la bordure par défaut */
    border-radius: 8px; /* Bords arrondis */
    margin: 5px 0;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    font-weight: 700;
    transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease; /* Smooth transitions */

}
.chat-choice:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    transform: scale(1.05); /* Slight scale up for emphasis */
    background-color: #FFD700; /* Color change on hover */
}

.chat-back-button {
    text-align: left;
    border: none;
    display: block;
    background: #F9D100;
    color: #333;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: 0px;
    left: 0;
    cursor:pointer;
}


.moviesListe, .sallesListe {
    margin:0;
    padding:0;
}
.moviesListe li, .sallesListe li {
    text-align:center;
    margin:0;
    padding:0;
    list-style-type:none;
    transition: transform 0.3s ease;
}
.moviesListe li a, .sallesListe li a {
    background-color: #333;
    color: #EEE;
    border: none;
    border-radius: 8px;
    margin: 5px 0;
    border-left: 10px solid #F9D100;
    padding: 6px;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    display: block;
    font-size:14px;
    transition: background-color 0.3s ease;
}
.moviesListe li:hover, .sallesListe li:hover {
    transform: translateX(-10px); /* Slight lift effect */
    /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);*/
}

.moviesListe li a:hover, .sallesListe li a:hover {
    background-color: #111; /* Darken on hover */
    color: #FFF; /* Optional: change text color on hover */
}
.moviesListe li a span, .sallesListe li a span {
    font-size:0.7em;
}

h2 {
    font-size:22px;
    margin: 0 0 5px 0;
}
h3 {
    font-size:18px;
    margin: 0 0 5px 0;
}


.chat-fermer {
    position: absolute;
    right: 0px;
    top: 0;
    background: #333;
    border: 0;
    border-radius: 50%;
    color:#EEE;
    font-size: 10px;
    padding: 6px 8px;
    cursor:pointer;
}
.chat-fermer:hover {
    cursor:pointer;
}

.chat-informations {
    color:#999;
    font-style:italic;
    font-size:10px;
    text-align: center;
}


.trailer-option, .news-option, .salle-option {
    position: relative;
    display: block; /* Assurez-vous que c'est un bloc si ce n'est pas déjà le cas */
    width: 100%; /* Définir la largeur ou s'assurer qu'elle est définie ailleurs */
    height: auto; /* Adapter selon vos besoins, peut être une hauteur fixe pour mieux contrôler l'aspect */
    background-size: cover; /* Si vous avez une image de fond */
    background-position: center; /* Assure que l'image de fond est bien centrée */
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEE;
    text-align:left;
}

.trailer-option:after {
    content: '';
    background-image: url('../assets/img/play-button.svg'); /* Vérifiez le chemin */
    background-repeat: no-repeat;
    background-position: center; /* Centrer l'image du bouton 'play' */
    height: 64px;
    width: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centrage précis de l'icône */
    background-size: cover;
}
.trailer-option h4, h3.tleft, .salle-option h4 {
    padding: 0;
    margin: 0;
    text-align: left;
}
.trailer-option img {
    width: 300px;
    height: 160px;
    background: #EEE;
    max-width:100%;
}

.link {
    color: #111;
    border-bottom: 3px solid #F9D100;
    text-decoration: none;
    text-align:left;
    font-weight: 500;
}

.news-option img {
    width:100%;
}
.news-option h4 {
    margin: 0;
    padding: 0 0 2px 0;
    text-align: left;
}
.news-option p {
    margin: 0;
    padding: 0;
    text-align: left;
}






